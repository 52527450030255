import React, { Suspense, useEffect } from "react";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BeatLoader } from "react-spinners";
import { Redirect, Route, Switch } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { AppStateType } from "./types";
import WrapperRender from "./hoc/WrapperRender";
import { useTranslation } from "react-i18next";
import { checkAuthState } from "./store/Effects";
import Voucher from './containers/Voucher/Product'
import Gifts from './containers/Gifts/Product'
import Promo from './containers/Promo/Product'
const Layout = React.lazy(() => import("./components/Layout/Layout"));
const Product = React.lazy(() => import("./containers/Product/Product"));
const PaymentSuccess = React.lazy(() => import("./containers/PaymentSuccess/PaymentSuccess"));
const ProductDetail = React.lazy(() => import("./containers/Product-detail/Product"));
const ProductOrderd = React.lazy(() => import("./containers/Product-ordered/Product"));
const ProductCart = React.lazy(() => import("./containers/Product-Cart/Product"));
const ProductCheckout = React.lazy(() => import("./containers/Product-Checkout/Product"));
const ProductInvoices = React.lazy(() => import("./containers/Invoices/Product"));
const Faqs = React.lazy(() => import("./containers/Faqs/Faqs"));
const ExplainerVideos = React.lazy(() =>
  import("./containers/ExplainerVideos/ExplainerVideos")
);
const Home = React.lazy(() => import("./containers/Home/Home"));
const Login = React.lazy(() => import("./containers/Login/Login"));
const Logout = React.lazy(() => import("./containers/Logout/Logout"));
const Profile = React.lazy(() => import("./containers/Profile/Profile"));
const Register = React.lazy(() => import("./containers/Register/Register"));
const Settings = React.lazy(() => import("./containers/Settings/Settings"));
const ForgetPassword = React.lazy(() =>
  import("./containers/ForgetPassword/ForgetPassword")
);

function App() {
  const store = useSelector(
    (state: AppStateType) => state.mainStore,
    shallowEqual
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.setItem("accessToken", store.accessToken)
    dispatch(checkAuthState());
  }, [dispatch]);

  let routes = (
    <Switch>
      <Route path={"/"} exact component={Home} />
      <Route path={"/product/:Id"} component={ProductDetail} />
      <Route path={"/ordered/:Id"} component={ProductOrderd} />
      <Route path={"/product"} component={Product} />
      <Route path={"/vouchers"} component={Voucher} />
      <Route path={"/gifts"} component={Gifts} />
      <Route path={"/promos"} component={Promo} />
      <Route path={"/invoice"} component={ProductInvoices} />
      <Route path={"/faqs"} component={Faqs} />
      <Route path={"/verification/:orderId/:tokenId"} component={PaymentSuccess} />
      <Route path={"/cart"} component={ProductCart} />
      <Route path={"/checkout"} component={ProductCheckout} />
      <Route path={"/explainer-video"} component={ExplainerVideos} />
      <Route path={"/profile"} component={Profile} />
      <Route path={"/settings"} component={Settings} />
      <Route path={"/logout"} component={Logout} />
      <Redirect to={"/"} />
    </Switch>
  );

  if (store.accessToken === "") {
    routes = (
      <Switch>
        <Route path={"/login"} component={Login} />
        <Route path={"/"} exact component={Home} />
        <Route path={"/faqs"} component={Faqs} />
        <Route path={"/register"} component={Register} />
        <Route path={"/explainer-video"} component={ExplainerVideos} />
        <Route path={"/product/:Id"} component={ProductDetail} />
        <Route path={"/forget-password"} component={ForgetPassword} />
        {/* <Redirect to={"/login"} /> */}
      </Switch>
    );
  }
  return (
    <SimpleBar style={{ maxHeight: "100vh" }}>
      <Suspense
        fallback={
          <div
            style={{
              height: "100vh",
              width: "100%",
              margin: "0 auto",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <BeatLoader loading={true} color={"#707070"} size={15} margin={3} />
          </div>
        }>
        <WrapperRender
          render={() => <Layout>{routes}</Layout>}
          pageTitle={t(store.pageTitle)}
        />
      </Suspense>
    </SimpleBar>
  );
}

export default App;
