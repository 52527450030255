import React, { useEffect } from "react";
import classes from "./Product.module.scss";
import { useRouteMatch, Switch, Route, useHistory } from "react-router-dom";
import { CusPaper } from "../../components/StyledComponents";
import { useSelector, shallowEqual } from "react-redux";
import { AppStateType } from "../../types";

const List = React.lazy(() => import("./List/List"));
const Token = React.lazy(() => import("./Token/Token"));
const Advertisement = React.lazy(() => import("./Advertisement/Advertisement"));
const Invoice = React.lazy(() => import("./Invoice/Invoice"));

function Product() {
  const match = useRouteMatch();
  const history = useHistory();

  const store = useSelector(
    (state: AppStateType) => state.mainStore,
    shallowEqual
  );

  useEffect(() => {
    if (store.user.profile === null) {
      history.push("/profile");
    }
  }, [store.user.profile, history]);

  const listRoutes = [
    {
      path: `/token`,
      component: Token,
      title: "menu.token",
    },
    {
      path: "/advertisement/:userToken",
      component: Advertisement,
      title: "menu.advert",
    },
    {
      path: "/advertisement/:userToken/:recordId",
      component: Advertisement,
      title: "menu.advert",
    },
    {
      path: "/invoice/:recordId",
      component: Invoice,
      title: "menu.invoice",
    },
    {
      path: "/",
      component: List,
      title: "menu.home",
    },
  ];

  const routes = (
    <Switch>
      {listRoutes.map((d) => (
        <Route
          path={`${match.path}${d.path}`}
          component={d.component}
          key={d.path}
          exact={true}
        />
      ))}
    </Switch>
  );

  return (
    <div className={classes.Main}>
      <CusPaper className={classes.Product}>{routes}</CusPaper>
    </div>
  );
}

export default Product;
