const apiUrls = {
  //baseURL: "http://localhost:51000",
   baseURL: "http://api.beelicensed.com/",
  uploadedURL: "/uploads/",
  login: () => "/login",
  checkUserStatus: () => "/checkUserStatus",
  register: () => "/register",
  changePassword: () => "/changePassword",
  forgetPassword: () => "/forgetPassword",
  uploadProfilePic: () => "/uploadProfilePic",
  addUserProfile: () => "/addUserProfile",
  editUserProfile: () => "/editUserProfile",
  getUserProfile: () => "/getUserProfile",
  getUserGroup: (
    active?: true | false,
    std?: true | false,
    id?: string,
    title?: string
  ) =>
    `/getUserGroup?active=${active ? active : ""}&std=${std ? std : ""}&id=${
      id ? id : ""
    }&title=${title ? title : ""}`,
  getCategoiesWithSub: () => "/getCategory?subCategory=true&name=couponcard",
  getTokenClick: (ugIds: string) => `/getTokenClick?sort=price`,
  addUserToken: () => `/addUserToken`,
  GetAuthorizedProducts: () => `/GetAuthorizedProducts`,
  getUserTokens: () => `/getUserTokens?active=true`,
  addAdvertisement: (userTokenId: string) => `/addAdvertisement/${userTokenId}`,
  editAdvertisement: (adverId: string) => `/editAdvertisement/${adverId}`,
  uploadAdverImage: (adverId: string) => `/uploadAdverImage/${adverId}`,
  getFaq: () => `getFaq?groupName=Poppingtime&sort=-priority`,
  getWorking: () => `GetAllWorking?groupName=Poppingtime`,
};

export default apiUrls;
