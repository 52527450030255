import { ActionsEnum, ActionsType, AppMainStore } from "../types";
import produce from "immer";

export const initialState: AppMainStore = {
  appStatus: { error: false, loading: false, responseText: "" },
  accessToken: "",
  user: {
    _id: "",
    email: "",
    profile: "",
    role: "",
    username: "",
  },
  pageTitle: "",
  userGroups: [],
  tokenData: [],
  providerProductData:[],
  categories: [],
  userTokens: [],
  userProfile: {
    addition: "",
    city: "",
    country: "",
    firstName: "",
    lastName: "",
    houseNo: "",
    phoneNo: "",
    picture: "",
    postalCode: "",
    province: "",
    street: "",
    town: "",
    companyName: ''
  },
  faqs: [],
  working:[],
};

export function Reducer(state = initialState, action: ActionsType) {
  switch (action.type) {
    case ActionsEnum.APP_STATUS:
      return produce(state, (draft) => {
        draft.appStatus = action.payload;
      });
    case ActionsEnum.ACCESS_TOKEN:
      return produce(state, (draft) => {
        draft.accessToken = action.payload;
      });
    case ActionsEnum.USER_DETAILS:
      return produce(state, (draft) => {
        draft.user = action.payload;
      });
    case ActionsEnum.LOGOUT:
      return (state = initialState);
    case ActionsEnum.PAGE_TITLE:
      return produce(state, (draft) => {
        draft.pageTitle = action.payload;
      });
    case ActionsEnum.GET_USER_GROUPS:
      return produce(state, (draft) => {
        draft.userGroups = action.payload;
      });
    case ActionsEnum.GET_USER_PROFILE:
      return produce(state, (draft) => {
        draft.userProfile = action.payload;
      });
    case ActionsEnum.GET_TOKEN_DATA:
      return produce(state, (draft) => {
        draft.tokenData = action.payload;
      });
      case ActionsEnum.GET_PROVIDER_PRODUCTS:
        console.log("action.payload", action.payload)
        return produce(state, (draft) => {
          draft.providerProductData = action.payload;
        });
    case ActionsEnum.GET_CATEGORIES_DATA:
      return produce(state, (draft) => {
        draft.categories = action.payload;
      });
    case ActionsEnum.GET_USER_TOKENS:
      return produce(state, (draft) => {
        draft.userTokens = action.payload;
      });
    case ActionsEnum.GET_FAQ_DATA: 
      return produce(state, draft => {
        draft.faqs = action.payload
      })
      case ActionsEnum.GET_WORKING_DATA:
        return produce(state, (draft) => {
          draft.working = action.payload;
        });
    default:
      return state;
  }
}
