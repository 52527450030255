import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import {
  CusButtonPrimary,
  CusButtonSecondary,
  CusDialog,
  CusDialogActions,
  CusDialogContent,
  CusDialogContentText,
  CusDialogTitle,
} from "./StyledComponents";
import { useTranslation } from "react-i18next";

interface Props {
  type: "success" | "error" | "confirm";
  title?: string;
  content: string;
  open: boolean;
  onCancel: () => void;
  onOkay?: () => void;
}

function DialogBox(props: Props) {
  return props.type === "success" || props.type === "error" ? (
    <ErrorSuccessDialogBox
      type={props.type}
      open={props.open}
      content={props.content}
      onCancel={props.onCancel}
    />
  ) : (
    <ConfirmDialogBox
      type={props.type}
      open={props.open}
      content={props.content}
      onCancel={props.onCancel}
      onOkay={props.onOkay}
    />
  );
}

function ErrorSuccessDialogBox(props: Props) {
  const { t } = useTranslation();
  return (
    <CusDialog
      open={props.open}
      onClose={props.onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <CusDialogTitle id="alert-dialog-title">
        {props.type === "error" ? (
          <div style={{ color: "red" }}>
            <FontAwesomeIcon
              icon={faTimes}
              size="1x"
              style={{ marginRight: "6px" }}
            />{" "}
            {t("meta.error")}
          </div>
        ) : props.type === "success" ? (
          <div style={{ color: "green" }}>
            <FontAwesomeIcon
              icon={faCheck}
              size="1x"
              style={{ marginRight: "6px" }}
            />{" "}
            {t("meta.success")}
          </div>
        ) : null}
      </CusDialogTitle>
      <CusDialogContent>
        <CusDialogContentText id="alert-dialog-description">
          {props.content}
        </CusDialogContentText>
      </CusDialogContent>
      <CusDialogActions>
        <CusButtonPrimary onClick={props.onCancel} color="primary" autoFocus>
          {t("button.okay")}
        </CusButtonPrimary>
      </CusDialogActions>
    </CusDialog>
  );
}

function ConfirmDialogBox(props: Props) {
  const { t } = useTranslation();
  return (
    <CusDialog
      open={props.open}
      onClose={props.onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{ minWidth: "200px" }}
    >
      <CusDialogTitle id="alert-dialog-title">
        {props.title ? props.title : t("meta.confirm")}
      </CusDialogTitle>
      <CusDialogContent>
        <CusDialogContentText id="alert-dialog-description">
          {props.content}
        </CusDialogContentText>
      </CusDialogContent>
      <CusDialogActions>
        <CusButtonPrimary onClick={props.onOkay} color="primary">
          {t("button.okay")}
        </CusButtonPrimary>
        <CusButtonSecondary onClick={props.onCancel} color="primary" autoFocus>
          {t("button.cancel")}
        </CusButtonSecondary>
      </CusDialogActions>
    </CusDialog>
  );
}

export default DialogBox;
