import {
  ActionsEnum,
  ActionsType,
  IAppStatus,
  IUserData,
  IUserGroupData,
  IUserProfileData,
  ICategoriesData,
  ITokenData,
  IUserTokenData,
  IFAQData,
  IProviderProductData,
  IWorkingData
} from "../types";

export function authStatusAction(token: string): ActionsType {
  return {
    type: ActionsEnum.ACCESS_TOKEN,
    payload: token,
  };
}

export function appStatusAction(status: IAppStatus): ActionsType {
  return {
    type: ActionsEnum.APP_STATUS,
    payload: status,
  };
}

export function addUserDataAction(user: IUserData): ActionsType {
  return {
    type: ActionsEnum.USER_DETAILS,
    payload: user,
  };
}

export function logoutAction(): ActionsType {
  localStorage.removeItem("token");
  localStorage.removeItem("expiresIn");
  return {
    type: ActionsEnum.LOGOUT,
  };
}

export function pageTitleAction(title: string): ActionsType {
  return {
    type: ActionsEnum.PAGE_TITLE,
    payload: title,
  };
}

export function getUserGroupsDataAction(data: IUserGroupData[]): ActionsType {
  return {
    type: ActionsEnum.GET_USER_GROUPS,
    payload: data,
  };
}

export function getUserProfileAction(data: IUserProfileData): ActionsType {
  return {
    type: ActionsEnum.GET_USER_PROFILE,
    payload: data,
  };
}

export function getCategoriesAction(data: ICategoriesData[]): ActionsType {
  return {
    type: ActionsEnum.GET_CATEGORIES_DATA,
    payload: data,
  };
}

export function getTokenDataAction(data: ITokenData[]): ActionsType {
  return {
    type: ActionsEnum.GET_TOKEN_DATA,
    payload: data,
  };
}
export function getProviderProductDataAction(data: IProviderProductData[]): ActionsType {
  console.log("im from action",data)
  return {
    type: ActionsEnum.GET_PROVIDER_PRODUCTS,
    payload: data,
  };
}

export function getUserTokenDataAction(data: IUserTokenData[]): ActionsType {
  return {
    type: ActionsEnum.GET_USER_TOKENS,
    payload: data,
  };
}

export function getFAQDataAction(data: IFAQData[]): ActionsType {
  return {
    type: ActionsEnum.GET_FAQ_DATA,
    payload: data,
  };
}
export function getWorkingDataAction(data: IWorkingData[]): ActionsType {
  return {
    type: ActionsEnum.GET_WORKING_DATA,
    payload: data,
  };
}