import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { AppStateType } from "../types";
import Loader from "../components/Loader";
import DialogBox from "../components/DialogBox";
import { Helmet } from "react-helmet";
import { appStatusAction } from "../store/Actions";

interface Props {
  render: () => JSX.Element;
  pageTitle?: string;
  redirectUrl?: string;
}

function WrapperRender(props: Props) {
  const status = useSelector(
    (state: AppStateType) => state.mainStore.appStatus,
    shallowEqual
  );
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setShowDialog(status.error);
  }, [status.error]);

  useEffect(() => {
    setShowDialog(status.responseText !== "");
  }, [status.responseText]);

  const onOkay = () => {
    setShowDialog(false);
    dispatch(
      appStatusAction({
        loading: false,
        error: false,
        responseText: "",
      })
    );
    if (props.redirectUrl && props.redirectUrl === "")
      window.location.assign(props.redirectUrl);
  };

  return (
    <>
      <Helmet>
        <title>{props.pageTitle}</title>
      </Helmet>
      {status.loading ? <Loader /> : null}
      {status.error ? (
        <DialogBox
          type={"error"}
          content={status.responseText}
          open={showDialog}
          onCancel={onOkay}
        />
      ) : null}
      {!status.error && status.responseText !== "" ? (
        <DialogBox
          type={"success"}
          content={status.responseText}
          open={showDialog}
          onCancel={onOkay}
        />
      ) : null}
      {props.render()}
    </>
  );
}

export default WrapperRender;
