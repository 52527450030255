import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

const theme = createMuiTheme({
  // palette: {
  // 	primary: {
  // 		main: '#f68c1e'
  // 	}
  // }
});

export default theme;
